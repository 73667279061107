import {
  useState,
  useEffect,
  Fragment,
  useMemo,
} from 'react';
import Input from 'components/common/inputs/Input';
import ShadowCard from 'components/common/cards/ShadowCard';
import useValidationConsolidator from 'hooks/useValidationConsolidator';
import MarketsList from 'constants/markets';
import TermsPageModal from 'components/common/modals/TermsModal';
import {
  Checkbox,
  Stack,
  FormControlLabel,
  Typography,
  Divider,
  Link,
} from '@newulife/common';
import { useRecoilValue, useRecoilState } from 'recoil';
import { formState, inputState } from 'state';
import terms from 'api/terms';
import { find, isEmpty } from 'lodash';
import replaceLabelPlaceholders from 'helpers/replaceLabelPlaceholders';
import { getEntryByLocale } from 'api/contentful';
import CONTENTFUL from 'constants/contentful';
import product from '../../../../api/product';

function GeneralSection(props) {
  const [contentfulKRTerms, setContentfulKRTerms] = useState(null); // Korea Enrollment Terms and Conditions
  const [marketingTerms, setMarketingTerms] = useState(null);
  const [form, setForm] = useRecoilState(formState);
  const [inputs, setInputs] = useRecoilState(inputState);
  const [termsData, setTermsData] = useState(null);
  const [modalOpen, setModalOpen] = useState({ open: false, modalProps: {} });
  const { localeId, countryInfo, locale, ...rest } = useRecoilValue(formState)
  const isKoreaTerms = props.isTerms && countryInfo?.iso2 === 'KR' || false
  const isThailandTerms = props.isTerms && countryInfo?.iso2 === 'TH' || false
  const { config, data = {}, isValid, inputs: dictionary, name } = props;

  useEffect(() => {
    
    const getContentfulTerms = async () => {
      if (props.isTerms) {
        const contentfulMarketingTerms = await getEntryByLocale(CONTENTFUL.marketing_optin_terms, form.locale)
        setMarketingTerms(contentfulMarketingTerms)
  
        if(isKoreaTerms) {
          const contentfulKRTerms = await getEntryByLocale(CONTENTFUL.korea_terms, form.locale)
          setContentfulKRTerms(contentfulKRTerms)
        }
      }
    }
    
    getContentfulTerms()
  },[])

  const [requiredOptions, optionalOptions] = useMemo(() => {
    if (!isKoreaTerms) return [[], []];
    if (!contentfulKRTerms || !termsData) return [[], []];
    return [
      contentfulKRTerms?.fields?.requiredCheckboxItems
        ?.map?.((o) => mapTerms(o, termsData))
        .filter((o) => !!o) || [],
      contentfulKRTerms?.fields?.optionalCheckboxItems
        ?.map?.((o) => mapTerms(o, termsData))
        .filter((o) => !!o) || [],
    ];
  }, [
    termsData,
    isKoreaTerms,
    contentfulKRTerms,
  ]);

  const marketingOptinOptions = useMemo(() => {
    if (!marketingTerms || !termsData) return [];

    // Korea doesn't include the marketing opt-in terms for functional reasons, so we'll handle these differently
    if (isKoreaTerms)
      return marketingTerms?.fields?.optionalCheckboxItems?.map(
        (o) => o?.fields,
      ); 

    return (
      marketingTerms?.fields?.optionalCheckboxItems
        ?.map?.((o) => mapTerms(o, termsData))
        .filter((o) => !!o) || []
    );
  }, [termsData, marketingTerms, isKoreaTerms]);

  function mapTerms(option, termsData) {
    let temp = {};
    termsData?.forEach((o1) => {
      if (o1.AreaId === option?.fields?.byDesignAreaId) temp = o1;
    });
    if (isEmpty(temp)) return null;
    return {
      ...temp,
      ...option.fields,
    };
  }

  //Set default values for terms
  useEffect(() => {
    if (!requiredOptions.length && !optionalOptions.length && !marketingOptinOptions.length) {
      return;
    }

    // Add marketin terms misc fields to inputs if korea
    setInputs((prev) => {

      if (isKoreaTerms) {
        const marketingTermsMiscFields = marketingOptinOptions?.reduce(
          (acc, marketingTerm) => {
            const fieldName = marketingTerm?.byDesignMiscField;
            if (fieldName) {
              // Ensure that the field name exists
              acc[fieldName] = false; // Assuming you want to initialize it to false
            }
            return acc;
          },
          {},
        );

        return {
          ...prev,
          miscFields: {
            ...(prev.miscFields || {}),
            ...marketingTermsMiscFields,
          },
        };
      }

      return prev
    })      

    setForm((prev) => {
      const termsToProcess = isKoreaTerms
        ? [...(requiredOptions || []), ...(optionalOptions || [])]
        : [...(marketingOptinOptions || [])];
      const combinedTerms = termsToProcess.map((o, idx) => {
        /**
         * Handle if term already exists - this should capture user's selections if component rerenders
         */
        const existingTerm = prev?.terms?.find(
          (term) => term.TermId === o?.TermId,
        );

        if (existingTerm) {
          return existingTerm;
        } else {

          /**
           * handle Marketing terms for Markets that have them prechecked
           */
          const marketingTerm = marketingOptinOptions?.find(
            (term) => term.AreaId === o?.AreaId,
          );

          if (marketingTerm && config?.precheckMarketingOptIn) {
            return {
              TermId: o?.TermId,
              IsAgreedTo: true,
              ...(o.MiscField ? { MiscField: o.MiscField } : {}),
            };
          }

          return {
            TermId: o?.TermId,
            IsAgreedTo: false,
            ...(o.MiscField ? { MiscField: o.MiscField } : {}),
          };
        }
      });

      // Only update the state if combinedTerms has actually changed
      if (JSON.stringify(prev.terms) !== JSON.stringify(combinedTerms)) {
        return {
          ...prev,
          terms: combinedTerms,
        };
      } else {
        return prev;
      }
    });
  }, [requiredOptions, optionalOptions, marketingOptinOptions, isKoreaTerms, config?.precheckMarketingOptIn]);

  const fields = isKoreaTerms
    ? requiredOptions?.map?.((o) => o.TermId) || []
    : config?.enrollment?.form?.sections?.[name]?.fields || [];
  const { title, sectionList, list } = data;

  function allFieldsValidApiCall(validationValue) {
    isValid(validationValue);
  }

  // // Get proper value for Tax Validation
  const markets = new MarketsList();
  const market = markets.find(config?.market).country;

  const arrayOfInputValidationFunctions = useValidationConsolidator(
    fields,
    allFieldsValidApiCall,
  );

  const allChecked =
    form.terms && Object.values(form.terms).every((term) => term?.IsAgreedTo);

  const handleCheckAll = (e) => {
    if (allChecked) {
      document.querySelectorAll('.js-check').forEach((el) => {
        if (el.querySelector('input').checked)
          el.querySelector('input').click();
      });
    } else {
      document.querySelectorAll('.js-check').forEach((el) => {
        if (!el.querySelector('input').checked)
          el.querySelector('input').click();
      });
    }
  };

  const handleChange = (idx, idx1, field) => {
    const validationIndex = idx === 0 ? idx1 : idx1 + requiredOptions.length; // don't @ me
    
    // If Korea, we set the marketing misc fields based on the user interacting with the optional checkbox field
    setInputs((prev) => {
      if (field?.AreaId === -5) {
        const marketingTermsMiscFields = marketingOptinOptions?.reduce(
          (acc, marketingTerm) => {
            const fieldName = marketingTerm?.byDesignMiscField;
            const fieldValue = !prev.miscFields[fieldName];
            if (fieldName) {
              // Ensure that the field name exists
              acc[fieldName] = fieldValue; // Assuming you want to initialize it to false
            }
            return acc;
          },
          {},
        );

        return {
          ...prev,
          miscFields: {
            ...(prev.miscFields || {}),
            ...marketingTermsMiscFields,
          },
        }
      }
      return prev;
    })

    setForm((prev) => {
      const terms =
        prev.terms?.map?.((o) => {
          if (o.TermId === field?.TermId) {
            const temp = !!!o.IsAgreedTo;
            if (idx === 0)
              arrayOfInputValidationFunctions[validationIndex]?.(temp); // validate required fields only

            return { ...o, IsAgreedTo: temp };
          }

          /**
           * Handle Marketing terms for Korea
           * If checking optional info term, set marketing opt-in terms misc fields to true
           */
          if (field?.AreaId === -5 && marketingOptinOptions?.some((marketingTerm) => marketingTerm?.TermId === o.TermId)) {
            const temp = !!!o.IsAgreedTo;
            return { ...o, IsAgreedTo: temp };
          }

          return o;
        }) || [];

      return {
        ...prev,
        terms,
      };
    });
  };

  useEffect(() => {
    const getTerms = async () => {
    const localeId = await product.getLocaleId(countryInfo.name, locale)
      const res = await terms.post(
        localeId || 1,
        countryInfo?.country?.toUpperCase(),
      );
      setTermsData(res);
    }

    if (
      props.isTerms && locale && countryInfo.country
    ) {
      getTerms();
    }
  }, [locale, countryInfo.country, props.isTerms]);

  if (!fields?.length) return null;

  return (
    <>
      <ShadowCard title={title}>
        {isKoreaTerms ? (
          <Stack gap={4}>
            <div className="form-max flex-20 column">
              {sectionList?.map((item) => (
                <p key={item}>{item}</p>
              ))}
              <Stack gap={2}>
                <FormControlLabel
                  // data-testid={`toggle-${id}`}
                  sx={{
                    alignItems: 'flex-start',
                  }}
                  control={
                    <Checkbox
                      id="select-all"
                      // name={id}
                      // aria-label={label}
                      onChange={handleCheckAll}
                      checked={allChecked}
                    />
                  }
                  label={
                    <Stack gap={1} pt={0.3}>
                      <Typography
                        as="label"
                        variant="body3"
                        htmlFor="select-all"
                      >
                        {
                          contentfulKRTerms?.fields?.selectAllCheckboxItem
                            ?.fields?.name
                        }
                      </Typography>
                    </Stack>
                  }
                />
                <Typography variant="body3">
                  {
                    contentfulKRTerms?.fields?.selectAllCheckboxItem?.fields
                      ?.disclaimerText
                  }
                </Typography>
              </Stack>
            </div>
            <Divider />
            <div className="form-max flex-20 column">
              {[requiredOptions, optionalOptions].map(
                (o, idx) =>
                  o?.length > 0 && (
                    <Fragment key={`termsSection${idx}`}>
                      {optionalOptions?.length > 0 && (
                        <Typography variant="body1">
                          {idx === 0
                            ? contentfulKRTerms?.fields?.requiredSectionText
                            : contentfulKRTerms?.fields?.optionalSectionText}
                        </Typography>
                      )}

                      {o?.map?.((field, idx1) => (
                        <FormControlLabel
                          key={`terms${idx}${idx1}`}
                          sx={{
                            alignItems: 'flex-start',
                          }}
                          control={
                            <Checkbox
                              className="js-check"
                              id={field?.TermId.toString()}
                              name={field?.TermId.toString()}
                              checked={
                                !!find(form.terms, { TermId: field?.TermId })
                                  ?.IsAgreedTo
                              }
                              onChange={() => handleChange(idx, idx1, field)}
                            />
                          }
                          label={
                            <Stack gap={1} pt={0.3}>
                              {field?.checkboxLabel && (
                                <Typography
                                  as="label"
                                  variant="body3"
                                  htmlFor={field?.TermId}
                                >
                                  {field?.checkboxLabel}
                                </Typography>
                              )}
                              {field?.linkedFile?.fields?.file?.url ? (
                                <Link
                                  href={field?.linkedFile?.fields?.file?.url}
                                  target="_blank"
                                  underline="hover"
                                  sx={{
                                    fontWeight: 500,
                                    display: 'block',
                                  }}
                                >
                                  {field?.linkLabel}
                                </Link>
                              ) : field?.modalText ? (
                                <Link
                                  underline="hover"
                                  sx={{
                                    fontWeight: 500,
                                    display: 'block',
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setModalOpen({
                                      open: true,
                                      modalProps: field,
                                    });
                                  }}
                                >
                                  {field?.linkLabel}
                                </Link>
                              ) : null}
                            </Stack>
                          }
                        />
                      ))}
                    </Fragment>
                  ),
              )}
            </div>
          </Stack>
        ) : (
          <div className="form-max flex-20 column">
            {fields.map((field, index) => {
              return (
                <Input
                  id={field}
                  key={`${name}-${field}`}
                  dictionary={dictionary}
                  isValid={arrayOfInputValidationFunctions[index]}
                  data={data}
                  country={config.market}
                  market={market}
                  dataTestId={`input-${field}`}
                  // onOpenModal={() => handleOpenModal(field)}
                />
              );
            })}
            {props.isTerms && marketingOptinOptions?.length > 0 && (
              <>
                <Divider />
                {[marketingOptinOptions].map(
                  (o, idx) =>
                    o?.length > 0 && (
                      <Fragment key={`termsSection-marketingOptin`}>
                        {marketingOptinOptions?.length > 0 && (
                          <Typography as="p" variant="body3">
                            {marketingTerms?.fields?.optionalFieldsDescription}
                          </Typography>
                        )}

                        {o?.map?.((field, idx1) => (
                          <FormControlLabel
                            key={`termsMarketingOptin${idx1}`}
                            sx={{
                              alignItems: 'flex-start',
                            }}
                            data-testid={`toggle-${field?.DocumentText}`}
                            control={
                              <Checkbox
                                className="js-check"
                                id={field?.TermId.toString()}
                                name={field?.TermId.toString()}
                                checked={
                                  !!find(form.terms, { TermId: field?.TermId })
                                    ?.IsAgreedTo
                                }
                                onChange={() => handleChange('marketingOptin', idx1, field)}
                              />
                            }
                            label={
                              <Stack gap={1} pt={0.3}>
                                {field?.checkboxLabel && (
                                  <Typography
                                    as="label"
                                    variant="body3"
                                    htmlFor={field?.TermId}
                                  >
                                    {replaceLabelPlaceholders(
                                      field?.checkboxLabel,
                                      inputs,
                                    )}
                                  </Typography>
                                )}
                                {field?.linkedFile?.fields?.file?.url ? (
                                  <Link
                                    href={field?.linkedFile?.fields?.file?.url}
                                    target="_blank"
                                    underline="hover"
                                    sx={{
                                      fontWeight: 500,
                                      display: 'block',
                                    }}
                                  >
                                    {field?.linkLabel}
                                  </Link>
                                ) : field?.modalText ? (
                                  <Link
                                    underline="hover"
                                    sx={{
                                      fontWeight: 500,
                                      display: 'block',
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setModalOpen({
                                        open: true,
                                        modalProps: field,
                                      });
                                    }}
                                  >
                                    {field?.linkLabel}
                                  </Link>
                                ) : null}
                              </Stack>
                            }
                          />
                        ))}
                      </Fragment>
                    ),
                )}
              </>
            )}
          </div>
        )}
      </ShadowCard>
      {modalOpen.open && (
        <TermsPageModal
          {...modalOpen.modalProps}
          close={() => setModalOpen({ open: false, modalProps: {} })}
        />
      )}
    </>
  );
}
export default GeneralSection;
