const TH = {
  market: 'TH',
  enrollment: {
    form: {
      sections: {
        terms: {
          fields: ['AGE', 'TH_CONSUMER_PROTECTION', 'TERMS', 'TH_PDPA'],
        },
      },
    },
  },
  inputs: {
    fields: ['ADDRESS1', 'ADDRESS2', 'ZIP', 'CITY', 'STATE'],
    ZIP: {
      required: false,
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      type: 'dropdown',
      labelRef: 'PROVINCE',
    },
    TH_CONSUMER_PROTECTION: { type: 'switch' },
    TH_PDPA: { type: 'switch' },
  },
};
export default TH;
